import { runningOnServer } from '@mntn-dev/utilities'
import type { ExactObject } from '@mntn-dev/utility-types'
import type { UriKind } from './types.ts'

// biome-ignore lint/complexity/noBannedTypes: Relax, it's fine.
export type EmptyParams = {}

export type RouteRegistration<
  TPattern extends string,
  TRouteParams extends EmptyParams = EmptyParams,
> = {
  pattern: TPattern
  // This is the original pre-fluent function. Let's keep this around for a minute incase we need to fallback to it or salvage anything from it as it evolves.
  // path: (options?: {
  //   route?: ExactObject<TRouteParams>
  //   query?: EmptyParams
  // }) => string
  params: (
    params: ExactObject<TRouteParams>
  ) => RouteRegistration<TPattern, TRouteParams>
  query: (params: EmptyParams) => RouteRegistration<TPattern, TRouteParams>
  toRelativeUrl: () => string
  toAbsoluteUrl: () => string
  toUrl: (uriKind: UriKind) => string
}

export function register<TPattern extends string>(pattern: TPattern) {
  return {
    toRoute: <TRouteParams extends EmptyParams = EmptyParams>(): [
      TPattern,
      () => RouteRegistration<TPattern, TRouteParams>,
    ] => {
      const routeBuilder: () => RouteRegistration<TPattern, TRouteParams> =
        () => {
          let path: string = pattern
          const toRelativeUrl = () => path
          const toAbsoluteUrl = () =>
            // TODO: Move getClientBaseUrl function from magicsky to somewhere app & env common and hook into it from here.
            // Maybe a new package called app-common?
            // Ensure Storybook Activity still works. It seems to have issue with env from @mntn-dev/env
            `${runningOnServer() ? process.env.BASE_URL : window.location.origin}${path}`

          const route: RouteRegistration<TPattern, TRouteParams> = {
            pattern,
            // This is the original pre-fluent function to create a url.
            // Let's keep this around for a minute incase we need to fallback to it or salvage anything from it as route rendering evolves.
            // path: (options) => {
            //   const { route: routeParams, query: queryParams } = options || {}
            //   const routeRenderedPath = routeParams
            //     ? Object.keys(routeParams).reduce<string>((path, key) => {
            //         return path.replace(
            //           `:${key}`,
            //           `${routeParams[key as keyof ExactObject<TRouteParams>]}`
            //         )
            //       }, pattern)
            //     : pattern
            //   return queryParams
            //     ? `${routeRenderedPath}?${new URLSearchParams(queryParams).toString()}`
            //     : routeRenderedPath
            // },
            params: (routeParams) => {
              path = Object.keys(routeParams).reduce<string>((path, key) => {
                return path.replace(
                  `:${key}`,
                  `${routeParams[key as keyof ExactObject<TRouteParams>]}`
                )
              }, path)

              return route
            },
            query: (queryParams) => {
              path = `${path}?${new URLSearchParams(queryParams).toString()}`

              return route
            },
            toRelativeUrl,
            toAbsoluteUrl,
            toUrl: (uriKind) =>
              uriKind === 'relative' ? toRelativeUrl() : toAbsoluteUrl(),
          }

          return route
        }

      return [pattern, routeBuilder]
    },
  }
}
