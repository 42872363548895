export const Error404 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="182"
    height="161"
    viewBox="0 0 182 161"
    fill="none"
  >
    <g clipPath="url(#clip0_11841_106779)">
      <path
        d="M173.087 0.5H8.73311C6.55624 0.5 4.37937 1.40703 2.74671 3.03968C1.11406 4.67234 0.207031 6.84921 0.207031 9.02608V115.33H181.613V9.02608C181.613 6.84921 180.706 4.67234 179.073 3.03968C177.622 1.40703 175.445 0.5 173.087 0.5ZM170.91 102.087C170.91 102.813 170.729 103.539 170.547 104.264C170.184 104.99 169.822 105.534 169.277 106.078C168.733 106.622 168.189 106.985 167.463 107.348C166.738 107.711 166.012 107.711 165.286 107.711H16.3522C15.6265 107.711 14.9009 107.529 14.1753 107.348C13.4497 106.985 12.9054 106.622 12.3612 106.078C11.817 105.534 11.4542 104.99 11.0914 104.264C10.7286 103.539 10.7286 102.813 10.7286 102.087V16.6451C10.7286 15.1939 11.2728 13.7426 12.3612 12.8356C13.4497 11.7472 14.7195 11.2029 16.1708 11.2029H165.468C166.919 11.2029 168.37 11.7472 169.277 12.8356C170.366 13.924 170.91 15.1939 170.91 16.6451V102.087Z"
        fill="#41C0FF"
      />
      <path
        d="M0.207031 120.227V126.213C0.207031 127.301 0.388437 128.39 0.932655 129.478C1.47687 130.567 2.02109 131.474 2.74671 132.199C3.47234 132.925 4.37937 133.651 5.4678 134.013C6.55624 134.376 7.64467 134.739 8.73311 134.739H173.268C175.445 134.739 177.622 133.832 179.255 132.199C180.887 130.567 181.794 128.39 181.794 126.213V120.227H0.207031ZM100.343 129.297H81.4769C80.7512 129.297 80.0256 128.934 79.3 128.39C78.7558 127.846 78.393 127.12 78.393 126.213C78.393 125.487 78.7558 124.762 79.3 124.036C79.8442 123.492 80.5698 123.129 81.4769 123.129H100.343C101.069 123.129 101.794 123.492 102.52 124.036C103.064 124.58 103.427 125.306 103.427 126.213C103.427 126.939 103.064 127.664 102.52 128.39C101.794 129.115 101.069 129.297 100.343 129.297ZM170.184 129.66C169.64 129.66 169.096 129.478 168.552 129.115C168.007 128.753 167.645 128.39 167.463 127.846C167.282 127.301 167.1 126.757 167.282 126.213C167.463 125.669 167.645 125.125 168.007 124.762C168.37 124.399 168.915 124.036 169.459 124.036C170.003 123.855 170.547 124.036 171.091 124.217C171.636 124.399 171.998 124.762 172.361 125.306C172.724 125.85 172.905 126.394 172.905 126.939C172.905 127.664 172.543 128.39 171.998 128.934C171.636 129.297 170.91 129.66 170.184 129.66Z"
        fill="#41C0FF"
      />
      <path
        d="M105.059 144.356V138.914H76.7598V144.356H105.059Z"
        fill="#41C0FF"
      />
      <path
        d="M57.168 160.498C57.168 158.866 57.5308 157.415 58.075 155.963C58.6192 154.512 59.5262 153.242 60.6147 152.154C61.7031 151.065 62.973 150.158 64.4242 149.614C65.8755 149.07 67.3267 148.707 68.9594 148.707H112.678C114.311 148.707 115.762 149.07 117.213 149.614C118.665 150.158 119.934 151.065 121.023 152.154C122.111 153.242 123.018 154.512 123.563 155.963C124.107 157.415 124.47 158.866 124.47 160.498H57.168Z"
        fill="#41C0FF"
      />
      <path
        d="M51.0015 52.5631C47.3734 52.5631 43.7453 51.8375 40.48 50.3862C37.2147 48.935 34.1308 46.9395 31.5911 44.3998C31.2283 44.037 31.0469 43.4928 31.0469 42.9486C31.0469 42.4044 31.2283 41.8602 31.7725 41.4973C32.1353 41.1345 32.6795 40.9531 33.2237 40.9531C33.768 40.9531 34.3122 41.1345 34.675 41.6788C36.8519 43.8556 39.3915 45.6697 42.1126 46.7581C45.0151 48.028 47.9176 48.5722 51.0015 48.5722C54.0854 48.5722 57.1693 48.028 59.8904 46.7581C62.7929 45.4883 65.3326 43.8556 67.328 41.6788C67.6909 41.3159 68.2351 41.1345 68.7793 40.9531C69.3235 40.9531 69.8677 41.1345 70.2305 41.4973C70.5934 41.8602 70.7748 42.4044 70.9562 42.9486C70.9562 43.4928 70.7748 44.037 70.412 44.3998C67.8723 46.9395 64.7884 49.1164 61.5231 50.3862C58.2578 51.8375 54.6296 52.5631 51.0015 52.5631Z"
        fill="#41C0FF"
      />
      <path
        d="M130.82 52.5631C127.192 52.5631 123.564 51.8375 120.298 50.3862C117.033 48.935 113.949 46.9395 111.409 44.3998C111.047 44.037 110.865 43.4928 110.865 42.9486C110.865 42.4044 111.047 41.8602 111.591 41.4973C111.954 41.1345 112.498 40.9531 113.042 40.9531C113.586 40.9531 114.131 41.1345 114.493 41.6788C116.67 43.8556 119.21 45.6697 121.931 46.7581C124.833 48.028 127.736 48.5722 130.82 48.5722C133.904 48.5722 136.988 48.028 139.709 46.7581C142.611 45.4883 145.151 43.8556 147.146 41.6788C147.509 41.3159 148.053 41.1345 148.598 40.9531C149.142 40.9531 149.686 41.1345 150.049 41.4973C150.412 41.8602 150.593 42.4044 150.775 42.9486C150.775 43.4928 150.593 44.037 150.23 44.3998C147.691 46.9395 144.607 49.1164 141.341 50.3862C138.076 51.8375 134.448 52.5631 130.82 52.5631Z"
        fill="#41C0FF"
      />
      <path
        d="M128.823 96.2826C128.46 96.2826 128.279 96.2826 127.916 96.1012C127.735 95.9198 127.372 95.9198 127.19 95.557C122.111 90.2962 115.943 86.3053 109.231 83.4028C102.519 80.5003 95.2629 79.049 88.0066 79.049C80.7504 79.049 73.4942 80.5003 66.7821 83.4028C60.0701 86.3053 53.9023 90.2962 48.823 95.557C48.6416 95.7384 48.4601 95.9198 48.0973 96.1012C47.9159 96.2826 47.5531 96.2826 47.1903 96.2826C46.8275 96.2826 46.6461 96.2826 46.2833 96.1012C46.1019 95.9198 45.7391 95.9198 45.5577 95.557C45.3762 95.3756 45.1948 95.1942 45.0134 94.8314C44.832 94.65 44.832 94.2871 44.832 93.9243C44.832 93.5615 44.832 93.3801 45.0134 93.0173C45.1948 92.8359 45.1948 92.4731 45.5577 92.2917C50.9998 86.6681 57.7119 82.3144 64.9681 79.2305C72.2243 76.1466 80.0248 74.6953 87.8252 74.6953C95.6257 74.6953 103.426 76.328 110.682 79.2305C117.939 82.3144 124.469 86.6681 130.093 92.2917C130.456 92.6545 130.637 93.0173 130.637 93.3801C130.637 93.7429 130.637 94.2871 130.456 94.65C130.274 95.0128 130.093 95.3756 129.73 95.557C129.73 96.1012 129.186 96.2826 128.823 96.2826Z"
        fill="#41C0FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_11841_106779">
        <rect
          width="181.587"
          height="160"
          fill="white"
          transform="translate(0.207031 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
