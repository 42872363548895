import { type AnyUrn, type IdTag, parseUrn } from '@mntn-dev/domain-types'
import { route } from '../route.ts'
import type { UriKind, UrnUrl } from '../types.ts'

const idTagUrlBuilderMap: Record<
  Extract<IdTag, 'project' | 'offer'>,
  (id: string, uriKind: UriKind) => string
> = {
  project: (id, uriKind) =>
    route('/projects/:projectId').params({ projectId: id }).toUrl(uriKind),
  offer: (id, uriKind) =>
    route('/offers/:offerId').params({ offerId: id }).toUrl(uriKind),
}

function isIdTagInMap(
  idTag: IdTag
): idTag is Extract<IdTag, keyof typeof idTagUrlBuilderMap> {
  return idTag in idTagUrlBuilderMap
}

export const urnUrlBuilder: (
  urn: AnyUrn | undefined,
  uriKind?: UriKind
) => UrnUrl | undefined = (urn, uriKind = 'relative') => {
  if (urn === undefined) {
    return undefined
  }

  const result = parseUrn(urn)

  if (result === undefined) {
    return undefined
  }

  const { nid: idTag, nss: id } = result

  if (!isIdTagInMap(idTag)) {
    return undefined
  }

  const urlBuilder = idTagUrlBuilderMap[idTag]

  return { idTag, url: urlBuilder(id, uriKind) }
}
