import { register } from './register.ts'

const routes = [
  register('/').toRoute(),
  register('/api/auth/logout').toRoute(),
  register('/dashboard').toRoute(),
  register('/login').toRoute(),
  register('/offers').toRoute(),
  register('/offers/:offerId').toRoute<{ offerId: string }>(),
  register('/onboarding').toRoute(),
  register('/packages').toRoute(),
  register('/packages/:packageId').toRoute<{ packageId: string }>(),
  register('/packages/services').toRoute(),
  register('/profile').toRoute(),
  register('/projects').toRoute(),
  register('/projects/:projectId').toRoute<{ projectId: string }>(),
  register('/projects/:projectId/match').toRoute<{ projectId: string }>(),
  register('/projects/:projectId/match/maker/:userId').toRoute<{
    projectId: string
    userId: string
  }>(),
  register('/projects/:projectId/browse').toRoute<{
    projectId: string
  }>(),
  register('/projects/:projectId/review/:deliverableId').toRoute<{
    projectId: string
    deliverableId: string
  }>(),
  register('/projects/new').toRoute(),
  register('/settings').toRoute(),
  register('/users').toRoute(),
  register('/users/:userId').toRoute<{ userId: string }>(),
]

export default routes
