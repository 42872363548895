import routes from './routes.ts'

// RouteMap is a strongly typed map from the routes using the route pattern as a key
// E.g., { '/projects': {route for /projects} }

type InferRouteMap<T extends typeof routes> = {
  [K in T[number] as K[0]]: K[1]
}

const buildRouteMap = <T extends typeof routes>(routes: T): InferRouteMap<T> =>
  routes.reduce<Record<string, unknown>>((map, [pattern, route]) => {
    map[pattern] = route
    return map
  }, {}) as InferRouteMap<T>

const routeMap = buildRouteMap(routes)

// In order to more simply use routes from the routeMap, a strongly typed route function is needed to instantiate the route function to avoid confusion in the wild.
// Using the route directly from the routeMap would involve accessing the the route by key and also having to instantiate it, which might be confusing.
// Note: The reason route registrations are a function is because the fluent route building requires state, so each route usage needs to be instantiated.
//
// #Potentially confusing  ----v
// routeMap['/offers/:offerId']().params({ offerId: '123' })
//
// The route function effectively returns the route by key, instantiates it, while upholding type integrity.
//
// #Easier to reason about
// route('/offers/:offerId').params({ offerId: '123' })

type RouteKey = keyof typeof routeMap

type RouteType<K extends RouteKey> = ReturnType<(typeof routeMap)[K]>

const route = <K extends RouteKey>(key: K): RouteType<K> => {
  return routeMap[key]() as RouteType<K>
}

export { route, type RouteKey }
